import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
// Image 
import globe from '../../../assets/images/LearnAndEarn/globe-01.png'
import gift from '../../../assets/images/LearnAndEarn/gift.png'
import check from '../../../assets/images/LearnAndEarn/check.svg'


function SubscribeModal({show , onHide ,missionDetail ,handleClose}) {
    const [selectCheck, setSelectCheck] = useState('summary-online')
    const navigate = useNavigate();

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                <Modal.Title>
                    <h3 className="fw-bold">Start Your Journey</h3>
                    <h6 className='my-2'>How to complete this mission?</h6>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="w-100">
                    <div className="dropdown-main-container">
                        <small>Investing is one of the ways to grow wealth. It is a way to set money aside while you are busy with life, and have that money work for you so that you can fully reap the rewards in the future. Many rich people are putting their monies in stocks, because it is one of the fastest ways to maintain or even grow wealth.</small>
                        <br/>
                        <br/>
                        <small>At the end of this session, you will know how to:</small>
                        <div>
                            <small className='mx-2'>1.Screen good stocks through stock filters </small><br/>
                            <small className='mx-2'>    2.Identify methods to evaluate great companies to invest in</small><br/>
                            <small className='mx-2'>    3.Identify ways to buy cheap stocks through financial ratios</small>
                        </div>
                        <br/>
                        
                        <small>
                        Investing is one of the ways to grow wealth. It is a way to set money aside while you are busy with life, and have that money work for you so that you can fully reap the rewards in the future. Many rich people are putting their monies in stocks, because it is one of the fastest ways to maintain or even grow wealth.
                        Investing is a means to a happier ending. Legendary investor Warren Buffett defines investing as “the process of laying out money now in the expectation of receiving more money in the future.” The goal of investing is to put your money to work in one or more types of investment vehicles, with the hopes of growing your money over time. If you do not have the skills or money to start a business of your own, why not leverage on successful businesses through stock investing?
                        </small>
                    </div>
                    <div className=''>
                        <div className='fw-semibold'>User Task</div>
                        <div className='fs-6'>Insta ID</div>
                        <input className="form-control my-2" type='text' placeholder="Enter Here"  />
                    </div>   
                </div>
            </Modal.Body>
            <Modal.Footer>
            <div className='d-flex justify-content-end w-100 '>
                        <div className='d-flex  w-50 '>
                            <button  className='form-control  text-primary btn  mx-2 border solid' onClick={onHide}>Cancel</button>
                            <Link
                                to={selectCheck==='summary-scratch-code'?"/redeem":''}
                                state={'learn-and-earn'}
                            >
                                <div >
                                    <button  className='form-control btn btn-primary' >Continue</button>
                                </div>
                            </Link>
                            
                        </div>
                    </div>
            </Modal.Footer>
            
        </Modal>
    );
}

export default SubscribeModal;