import { useState, useEffect, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

//images
import siteLogo from "../../../assets/images/Purplelogo.png";
import searchIcon from "../../../assets/images/Navbar/search-Icon.svg";
import wwwIcon from "../../../assets/images/Navbar/wwwIcon.svg";
import toggler from "../../../assets/images/Navbar/toggler.svg";
import notificationIcon from "../../../assets/images/Auth/notificationIcon.svg";
import logoutIcon from "../../../assets/images/Auth/logoutIcon.svg";
import avatar from "../../../assets/images/Auth/avatar2.svg";
import profileIcon from "../../../assets/images/Auth/profileIcon.svg";
import zap from "../../../assets/images/Auth/zap.svg";
import setting from "../../../assets/images/Auth/settingsIcon.svg";
import bankNote from "../../../assets/images/Auth/bankNoteIcon.svg";
import supportIcon from "../../../assets/images/Auth/SupportIcon.svg";
import awardIcon from "../../../assets/images/Auth/awardIcon.svg";
import Password from "../../../assets/images/Sidebar/lock-01.png";
import Gift from "../../../assets/images/Sidebar/gift.png";
import Learn from "../../../assets/images/Sidebar/learnandearn.png";
import Cross from "../../../assets/images/Navbar/cross.png";


import { subscriptionStatusLearnAndEarn } from "../../../services/learnAndEarn.service";
import {
  setLearnAndEarnSubscriptionDetail,
  selectLearnAndEarnSubscriptionDetail,
} from "../../../redux/learnAndEarnSubscriptionSlice";

import { selectCategoryList } from "../../../redux/categorySlice";

//styles
import "../../../assets/scss/components/navbar/navbar.scss";
import "../../../assets/scss/components/Sidebar/MobileSideBar.scss";

import Button from "../button/Button";
import SearchModal from "../../Modals/SearchModal";

import {
  getUser,
  removeUserSession,
} from "../../../utils/Auth.Service";
import { logout } from "../../../services/auth.service";
import { searchService } from "../../../services/dashboard.service";
import {
  selectLoggedIn,
  selectToken,
  selectUserDetails,
  setLoggedIn,
  setToken,
  setUserDetail,
} from "../../../redux/userSlice";
import { selectSubscriptionDetail } from "../../../redux/subscriptionSlice";

const Navbar = () => {
  const token = useSelector(selectToken)
  const user = getUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector(selectLoggedIn);
  const userDetail = useSelector(selectUserDetails);
  const { t, i18n } = useTranslation();
  const [searchQuery, setSearchQuery] = useState();
  const [searchResult, setSearchResult] = useState(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languages = [
    {
      code: "en",
      lang: "Eng",
    },
    {
      code: "chi",
      lang: "Chinese",
    },
    {
      code: "malay",
      lang: "Malay",
    },
  ];

  const fetchUserSubscriptionStatus = async () => {
    const result = await subscriptionStatusLearnAndEarn();
    dispatch(setLearnAndEarnSubscriptionDetail(result?.data));
  };

  useEffect(() => {
    if (token) {
      fetchUserSubscriptionStatus();
    }
    // eslint-disable-next-line
  }, [token]);

  const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail);
  const subscription = useSelector(selectSubscriptionDetail)
  
  const location = useLocation()

  useEffect(() => {
    if (token && user) {
      dispatch(setLoggedIn(true));
    } else {
      dispatch(setLoggedIn(false));
    }
    // eslint-disable-next-line
  }, [token, user]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (scrollTop > 120) { // Adjust the scroll threshold as needed
        setIsCollapsed(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleLogoutClick = async () => {
    await logout();
    dispatch(setToken(null));
    dispatch(setUserDetail(null));
    removeUserSession();
    navigate("/");
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  // let timer;
  const handleSearch = async (event) => {
    setIsLoading(true);
    const { value } = event?.target;
    setSearchQuery(value)

    const result = await searchService({ searchQuery: value });
    setSearchResult(result?.data?.classes?.data);

    setIsLoading(false);
  };

  // eslint-disable-next-line
  const optimisedVersion = useCallback(debounce(handleSearch), []);

  //This function is used to clear the search
  function handleClick() {
    document.addEventListener("click", (e) => {
      if (e.target.id === "input-field") {
        setShowSearchModal(true);
      } else if (e.target.id === "search-modal-container") {
        setShowSearchModal(true);
      } else {
        setShowSearchModal(false);
      }
    });
  }

  const categories = useSelector(selectCategoryList);
  
  const getSubNotification = () => {
    if (subscription?.status === 'no_subscription') {
        return (
        <>
          Enhance your life and career with ReSkills Live Learning! <Link className="fw-bold fs-6 text-decoration-none" to={'/account/learn-and-earn'}>Join our 2 years premium subscription Promo now!</Link> 
        </>
        );
    } else if (subscription?.status === 'active' && subscription?.days_remaining <= 30 && subscription?.days_remaining > 0) {
        return (
          <>
            Your premium subscription ends in 30 days. <Link className="fw-bold fs-6 text-decoration-none" to={'/subscription'}>Extend here</Link>  to ensure you continue enjoying all premium features!
          </>
        );
    }  else if (subscription?.status === 'inactive') {
      return (
        <>
          You no longer have an active  subscription. We'd love to have you back,
          <Link className="fw-bold fs-6 text-decoration-none" to={'/subscription'}>subscribe to continue your lifelong learning journey with ReSkills!</Link>
        </>
      );
  }
    else if(subscription?.status === 'active' && subscription?.days_remaining > 30) {
        return (
            <>
                Your Premium access ends in {(subscription?.days_remaining) || '0'} days.{' '}
                <Link className="fw-bold fs-6 text-decoration-none" to={'/subscription'}>Click here to extend</Link>
            </>
        );
    }
};




  return (
    <div className="navbar-container">
       {login &&<div className="py-1 fst-italic fw-semibold text-primary text-center  bg-grey fs-6">
        {getSubNotification()}
      </div>}
      <nav className="navbar navbar-expand-lg navbar-light z-1">
        <div className="container-fluid  mx-4">
          <div className="login-out-container-larger-view">
            <div className="d-flex justify-content-between width-100 ">
              <Link className="navbar-brand" to="/">
                <img src={siteLogo} alt="site-logo" />
              </Link>
             { !((location?.pathname?.includes('privacy')) || (location?.pathname?.includes('about'))|| (location?.pathname?.includes('terms'))) &&
             
             <div className="login-out-container-smaller-view">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={!isCollapsed}
                  aria-label="Toggle collapse"
                  onClick={handleToggle}
                >
                  <img src={toggler} alt="" />
                </button>
              </div>}
            </div>
          </div>
          <div className="login-out-container-smaller-view w-100 small-flex">
           { !((location?.pathname?.includes('privacy')) || (location?.pathname?.includes('about')) || (location?.pathname?.includes('terms'))) &&
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={!isCollapsed}
              aria-label="Toggle collapse"
              onClick={handleToggle}
            >
              <img src={toggler} alt="" />
            </button>}

            <div className=" d-flex justify-content-center w-100 ">
              <div className="login-out-container-smaller-view">
                <Link className="navbar-brand" to="/">
                  <img src={siteLogo} alt="site-logo" />
                </Link>
              </div>
            </div>
          </div>
          
          <div className="d-flex position-relative search-width ">
            <div className="login-out-container-larger-view w-100">
            <div
              className="search-main-container"
              onClick={() => handleClick()}
            >
              <img src={searchIcon} alt="search-icon" className="search-icon" />
              <input
                className={`${searchQuery?.length > 0 ? "bg-white" : "bg-transparent"
                  } form-control ml-2 border-0 mx-2 `}
                type="search"
                id="input-field"
                placeholder="Search"
                autoComplete="off"
                aria-label="Search"
                onChange={optimisedVersion}
                onKeyUp={(e) =>
                  e.keyCode === 13 &&
                  navigate(
                    `/catagory?search=${encodeURIComponent(e.target.value)}`
                  )
                }
              />
              {showSearchModal && (
                <div
                  className="search-modal position-absolute"
                  id="search-modal-container"
                >
                  <SearchModal
                    searchQuery={searchQuery}
                    searchResult={searchResult}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
            </div>
          </div>


          {!((location?.pathname?.includes('privacy')) || (location?.pathname?.includes('about'))|| (location?.pathname?.includes('terms'))) &&
            <div className="login-out-container-smaller-view w-100">
          <div className="d-flex position-relative search-width ">
            <div
              className="search-main-container"
              onClick={() => handleClick()}
            >
              <img src={searchIcon} alt="search-icon" className="search-icon" />
              <input
                className={`${searchQuery?.length > 0 ? "bg-white" : "bg-transparent"
                  } form-control ml-2 border-0 mx-2 `}
                type="search"
                id="input-field"
                placeholder="Search"
                autoComplete="off"
                aria-label="Search"
                onChange={optimisedVersion}
                onKeyUp={(e) =>
                  e.keyCode === 13 &&
                  navigate(
                    `/catagory?search=${encodeURIComponent(e.target.value)}`
                  )
                }
              />
              {showSearchModal && (
                <div
                  className="search-modal position-absolute"
                  id="search-modal-container"
                >
                  <SearchModal
                    searchQuery={searchQuery}
                    searchResult={searchResult}
                    isLoading={isLoading}
                  />
                </div>
              )}
            </div>
            </div>
          </div>}

          <div
            className={`collapse ${!isCollapsed ? "show" : ""} navbar-collapse`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0  login-out-container-larger-view">
              <li className="dropdown ">
                <Link
                  className="nav-link "
                  to="/all-category"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {t("category")}
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="#">
                      Action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="#">
                      Something else here
                    </Link>
                  </li>
                </ul>
              </li>
              {login && (
                <li className="nav-item ">
                  <Link
                    className="nav-link"
                    to="/account/subscription"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    {t("subscription")}
                  </Link>
                </li>
              )}
              {userDetail && (
                <li className="nav-item min-w-ml">
                  <Link
                    className="nav-link"
                    to="/my-learning"
                    tabIndex="-1"
                    aria-disabled="true"
                  >
                    My Learning
                  </Link>
                </li>
              )}
            </ul>

            <div className="login-out-container-smaller-view">
              <div id="" className="sidenav">
                <div className="d-flex justify-content-end">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={!isCollapsed}
                  aria-label="Toggle collapse"
                  onClick={handleToggle}
                >
                  <img src={Cross} alt="" />
                </button>
                </div>
                {!login ? (
                  <div className="d-flex align-items-center gap-2 mx-2">
                    {!login && (
                      <div className=" mx-2">
                        <div className="" style={{ width: "290px" }}>
                          <hr style={{ color: "#98a2b3" }} />
                          <div
                            className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                            onClick={() => navigate("/login")}
                          >
                            <span className="fw-semibold text-primary">Login</span>
                          </div>
                        </div>
                        <div className="" style={{ width: "290px" }}>
                          <hr style={{ color: "#98a2b3" }} />
                          <div
                            className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                            onClick={() => navigate("/sign-up")}
                          >
                            <span className="fw-semibold text-primary">Sign Up</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="d-flex align-items-center gap-2 mx-2">
                    <div>
                      <div className="" style={{ width: "290px" }}>
                        <div className="text-left mx-2 mt-1  cursor-pointer d-flex gap-2">
                          <img
                            src={avatar}
                            alt="avatar"
                            style={{ width: "48px", height: "43px" }}
                          />
                          <div>
                            <p className="mb-0 fs-6 fw-bold">{`${userDetail?.first_name || ""
                              } ${userDetail?.last_name || ""}`}</p>
                            <p className="m-0 fs-6 text-secondary ">
                              {userDetail?.email}
                            </p>
                          </div>
                        </div>
                        <hr style={{ color: "#98a2b3" }} />
                        <div
                          className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                          onClick={() => navigate("/account/profile")}
                        >
                          <img src={profileIcon} alt="" />{" "}
                          <span>{t("profile")}</span>
                        </div>
                        {/* <div
                          className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                        // onClick={() => handleLogoutClick()}
                        >
                          <img src={setting} alt="" />{" "}
                          <span>{t("accountSettings")}</span>
                        </div> */}
                        <div
                          className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                          onClick={() => navigate("/account/subscription")}
                        >
                          <img src={zap} alt="" />{" "}
                          <span>{t("subscription")}</span>
                        </div>
                        <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() =>
                        navigate(
                          subscriptionDetail?.status === "active"
                            ? "/account/learn-and-earn/total-points"
                            : "/account/learn-and-earn"
                        )
                      }
                    >
                      <img
                        src={Learn}
                        alt=""
                        style={{ height: "17px", marginTop: "5px" }}
                      />{" "}
                      <span>Learn and Earn</span>
                    </div>
                        <hr style={{ color: "#98a2b3" }} />
                        <div className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                          onClick={() => navigate("/account/transaction-history")}
                        >
                          <img src={bankNote} alt="" />{" "}
                          <span>{t("transactionHistory")}</span>
                        </div>
                        <div className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                          onClick={() => navigate("/account/certificate")}>
                          <img src={awardIcon} alt="" />{" "}
                          <span>{t("certificates")}</span>
                        </div>
                        <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/account/change-password")}
                    >
                      <img
                        src={Password}
                        alt=""
                        style={{ height: "18px", marginTop: "4px" }}
                      />{" "}
                      <span>{t("changePassword")}</span>
                    </div>
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/redeem")}
                    >
                      <img
                        src={Gift}
                        alt=""
                        style={{ height: "15px", marginTop: "4px" }}
                      />{" "}
                      <span>{t("redeemGiftCard")}</span>
                    </div>
                        {/* <hr style={{ color: "#98a2b3" }} /> */}
                        {/* <div
                                                className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                                            >
                                                <img src={supportIcon} alt="" />{" "}
                                                <span>{t("support")}</span>
                                            </div> */}
                        <hr style={{ color: "#98a2b3" }} />
                        <div
                          role="button"
                          className="text-danger text-left mx-3 my-2 cursor-pointer d-flex gap-2"
                          onClick={() => handleLogoutClick()}
                        >
                          <img src={logoutIcon} alt="" />{" "}
                          <span>{t("logout")}</span>
                        </div>
                        <hr style={{ color: "#98a2b3" }} />
                        {userDetail && <div className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2" onClick={() => navigate("/my-learning")}>

                          <span>My Learning</span>
                        </div>}



                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex align-items-center gap-2 mx-2">
                  <div className="" style={{ width: "290px" }}>
                    <hr style={{ color: "#98a2b3" }} />
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/all-category")}
                    >
                      <span>{t("category")}</span>
                    </div>
                  </div>
                </div>
                <div className="mx-3 mb-5">
                  {categories?.length > 0 &&
                    categories?.map((item) => {
                      return (
                        <div className="" style={{ width: "290px" }} key={`category-${item?.id}`}>
                          <hr style={{ color: "#98a2b3" }} />
                          <div
                            className="text-left mx-3 mb-5 cursor-pointer d-flex gap-2"
                            onClick={() => navigate(`/catagory?search=${encodeURIComponent(item?.name)}`)}
                          >
                            <span>{item?.name}</span>
                          </div>
                          
                        </div>
                      )
                    })
                  }
                  <div className="scroll-margin-bottom">

                  </div>
                </div>





              </div>
            </div>

            {/* <div className="dropdown-container">
                            <img
                                src={wwwIcon}
                                alt="www-icon"
                                className="dropdown-menu-icon"
                            />
                            <select
                                className="dropdown-itemMenu bg-transparent"
                                onChange={(e) => changeLanguage(e.target.value)}
                            >
                                {languages.map((item, index) => {
                                    return (
                                        <option
                                            key={index}
                                            className="dropdown-items"
                                            value={item.code}
                                        >
                                            {item.lang}
                                        </option>
                                    );
                                })}
                            </select>
                        </div> */}

            {/* {!login && <div className={`collapse ${isCollapsed ? '' : 'd-block'} d-flex gap-2 navbar-login`} >
                            <Button
                                name="Login"
                                btnType="whiteButton"
                                onClick={() => navigate("/login")}
                            />
                            <div style={{ width: "70px" }}>
                                <Button name="Sign Up" onClick={() => navigate("/sign-up")} />
                            </div>
                        </div>} */}
          </div>

          <div className="login-out-container-larger-view">
            {!login ? (
              <div className="d-flex align-items-center gap-2 mx-2">
                <Button
                  name="Log in"
                  btnType="whiteButton"
                  onClick={() => navigate("/login")}
                />
                <div>
                  <Button name="Sign Up" onClick={() => navigate("/sign-up")} />
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center gap-2 mx-2">
                {/* <div>
                                    <img src={notificationIcon} alt="icon" />
                                </div> */}

                <Dropdown>
                  <Dropdown.Toggle className="toggle-button-container rounded-circle">
                    <img
                      src={userDetail?.image ? userDetail?.image : avatar}
                      alt="user"
                      className="rounded-circle"
                      style={{ width: "43px", height: "43px" }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-menu-container"
                    style={{ width: "290px" }}
                  >
                    <div className="text-left mx-2 mt-1  cursor-pointer d-flex gap-2">
                      <img
                        src={userDetail?.image || avatar}
                        alt="user"
                        className="rounded-circle"
                        style={{ width: "43px", height: "43px" }}
                      />
                      <div>
                        <p className="mb-0 fs-6 fw-bold">{`${userDetail?.first_name || ""
                          } ${userDetail?.last_name || ""}`}</p>
                        <p className="m-0 fs-6 text-secondary ">
                          {userDetail?.email}
                        </p>
                      </div>
                    </div>
                    <hr style={{ color: "#98a2b3" }} />
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/account/profile")}
                    >
                      <img src={profileIcon} alt="" />{" "}
                      <span>{t("profile")}</span>
                    </div>
                    {/* <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                    // onClick={() => handleLogoutClick()}
                    >
                      <img src={setting} alt="" />{" "}
                      <span>{t("accountSettings")}</span>
                    </div> */}
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/account/subscription")}
                    >
                      <img src={zap} alt="" /> <span>{t("subscription")}</span>
                    </div>
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() =>
                        navigate(
                          subscriptionDetail?.status === "active"
                            ? "/account/learn-and-earn/total-points"
                            : "/account/learn-and-earn"
                        )
                      }
                    >
                      <img
                        src={Learn}
                        alt=""
                        style={{ height: "17px", marginTop: "5px" }}
                      />{" "}
                      <span>Learn and Earn</span>
                    </div>
                    <hr style={{ color: "#98a2b3" }} />
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/account/transaction-history")}
                    >
                      <img src={bankNote} alt="" />{" "}
                      <span>{t("transactionHistory")}</span>
                    </div>
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/account/certificate")}
                    >
                      <img src={awardIcon} alt="" />{" "}
                      <span>{t("certificates")}</span>
                    </div>
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/account/change-password")}
                    >
                      <img
                        src={Password}
                        alt=""
                        style={{ height: "18px", marginTop: "4px" }}
                      />{" "}
                      <span>{t("changePassword")}</span>
                    </div>
                    <div
                      className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                      onClick={() => navigate("/redeem")}
                    >
                      <img
                        src={Gift}
                        alt=""
                        style={{ height: "15px", marginTop: "4px" }}
                      />{" "}
                      <span>{t("redeemGiftCard")}</span>
                    </div>
                    {/* <hr style={{ color: "#98a2b3" }} />
                                        <div
                                            className="text-left mx-3 mb-3   cursor-pointer d-flex gap-2"
                                        // onClick={() => handleLogoutClick()}
                                        >
                                            <img src={supportIcon} alt="" />{" "}
                                            <span>{t("support")}</span>
                                        </div> */}
                    <hr style={{ color: "#98a2b3" }} />
                    <div
                      className="text-danger text-left mx-3 my-2 cursor-pointer d-flex gap-2"
                      onClick={() => handleLogoutClick()}
                    >
                      <img src={logoutIcon} alt="" /> <span>{t("logout")}</span>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </nav>
      {/* <hr className="m-0 mb-1" /> */}
    </div>
  );
};

export default Navbar;
