import React, { useState ,useEffect } from 'react';
import Button from '../../components/common/button/Button';
import SiteSlider from '../../components/common/slider/SiteSlider';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { selectClassList } from '../../redux/classSlice';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { getClassService } from '../../services/dashboard.service';


const UpComingClasses = () => {

    const Classes = useSelector(selectClassList);
    const { slug } = useParams();
    const [upComing ,setUpComing] = useState([])

    const date = moment(new Date()).format("YYYY-MM-DD");


    const fetchTodayLiveClasses = async () => {
        try {
            const result = await getClassService({
                filter: slug ? slug : '',
                up_coming_classes:true,
                up_coming_date:date

            });
            setUpComing(result?.data?.classes?.data)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTodayLiveClasses();

    }, []);


    const currentDate = new Date();
   

   
    const getClassTitle = () => {
        switch (slug) {
            case 'onstage':
                return 'Upcoming OnStage'
            case 'onference':
                return 'Upcoming OnFerence'
            default:
                return 'Today’s LIVE sessions'
        }
    }

    const containerClass = upComing?.length === 1 ? 'today-centered-content' : '';
    return (
        <>
            {upComing?.length > 0 && (
                <div className="">
                    <h2 className="liveCourse-main-title fw-bold fs-4 mb-4 mx-3 my-4">
                        {getClassTitle()}
                    </h2>
                    <div className={containerClass}>
                        <SiteSlider
                            cardType="horizental"
                            noOfItems={upComing?.length}
                        >
                            {upComing?.map((item) => (
                                <div className="position-relative py-3 mx-2 " key={item.id}>
                                    <div>
                                        <img src={item.images ? item.images[0]?.image : item?.thumb_image} className="w-100 border-radius todays-classes" alt={`${item?.name}`} />
                                        <div className="position-absolute bottom-0 start-50 translate-middle mb-3">
                                            <Link className="text-dark text-decoration-none" to={`/class/${item.slug}`}>
                                                <Button
                                                    name="Register Now"
                                                    btnType="registerNow"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </SiteSlider>
                    </div>
                </div>
            )}
        </>
    );
};

export default UpComingClasses;