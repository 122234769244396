import React, { useState, useEffect } from "react";
import Account from "../account/Account";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";

// Image Import
import Cover from "../../assets/images/LearnAndEarn/main-fram-point.png";
import Smallicon from "../../assets/images/LearnAndEarn/reskillroundimage.png";
import ticket from "../../assets/images/LearnAndEarn/ticket-02.png";
import share from "../../assets/images/LearnAndEarn/Icon.png";
import star from "../../assets/images/LearnAndEarn/star.png";
import book from "../../assets/images/LearnAndEarn/book-open-02.png";
import Check from "../../assets/images/LearnAndEarn/check.png";

// Import Css

import "../../assets/scss/pages/learnAndEarn/learnAndEarn.scss";
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";
import Button from "../../components/common/button/Button";
import ShareModal from "../../components/Modals/ShareModal";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

//
import {
  getMissions,
  missionBegin,
  subscriptionStatusLearnAndEarn,
} from "../../services/learnAndEarn.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setLearnAndEarnSubscriptionDetail,
  selectLearnAndEarnSubscriptionDetail,
} from "../../redux/learnAndEarnSubscriptionSlice";
import SubscribeModal from "../../components/Modals/RateAndReviewModals/SubscribeModal";

const LearnAndEarnTotalPoints = () => {
  const [shareModal, setShareModal] = useState(false);
  const [rewardModal, setRewardModal] = useState(false);
  const [mission, setMission] = useState([]);
  const [missionDetail , setMissionDetail] = useState()

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail);
  const fetchUserSubscriptionStatus = async () => {
    const result = await subscriptionStatusLearnAndEarn();
    dispatch(setLearnAndEarnSubscriptionDetail(result?.data));
  };

  /**
   * @function getMissions
   * @param
   * @description This function is used fetch Mission List
   */
  const fetchMission = async () => {
    try {
      const result = await getMissions();
      setMission(result?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserSubscriptionStatus();
    fetchMission();
    // eslint-disable-next-line
  }, []);
  const handleShareModal = () => {
    setShareModal(!shareModal);
  };

  const handleRewardModal = () => {
    setRewardModal(!rewardModal);
  };

  /**
   * @function fetchMissionBegin
   * @param
   * @description This function is used fetch Mission List
   */
  const fetchMissionBegin = async (id) => {

    try {
      const result = await missionBegin(id);
      setMissionDetail(result?.data?.mission)
      setRewardModal(true)
    } catch (error) {
      console.log(error);
    }
  };
  


  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "failure") {
      navigate("/account/learn-and-earn");
    }
  }, [location, navigate]);

  return (
    <Account>
      <div className="tran-container d-flex  justify-content-center">
        <div className="main-tr-container">
          <h4 className=" fw-bold">ReSkills Learn & Earn Program</h4>
          <div className="shadow-sm rounded my-4 border border-light  flex-column p-3 bg-card-primary">
            <img
              src={Smallicon}
              style={{ height: "30px", width: "30px" }}
              alt="logo"
            />
            <div className="d-flex justify-content-between">
              <div className="fw-semibold mx-2 text-white">
                Total Earning Points (1 point = $1)
              </div>
              <div>
                <OverlayTrigger
                  placement="top"
                  style={{ width: "300px" }}
                  overlay={
                    <Tooltip id="tooltip-top">
                      <div style={{ width: "300px" }}>
                        Starting October 15th 2024, participants can request for
                        a withdrawal once they have accumulated a minimum of 50
                        points. Only one withdrawal request can be submitted at
                        a time. Please wait until after your initial request has
                        been approved or rejected before submitting another
                        request.
                      </div>
                    </Tooltip>
                  }
                >
                  <div className="text-white semibold m-2">
                    <IoIosInformationCircleOutline size={20} />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
            <div className="mx-2 d-flex justify-content-between">
              <div className="fs-4 fw-bold text-white">
                {Math.round(subscriptionDetail?.total_points * 100) / 100 || 0}
              </div>
              {subscriptionDetail?.total_points >= 30 && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    subscriptionDetail?.withdraw_status === "pending" ? (
                      <Tooltip id="tooltip-top">
                        Only one withdrawal request can be submitted at a time.
                        Please wait until your request is approved or rejected.
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <div
                    className="sub-btn-l"
                    onClick={() =>
                      navigate(
                        subscriptionDetail?.withdraw_status === "pending"
                          ? ""
                          : "/account/learn-and-earn/beneficiary-details"
                      )
                    }
                  >
                    <ButtonWithArrow
                      name="Claim Reward"
                      type="whitebtn"
                      disabled={
                        subscriptionDetail?.withdraw_status === "pending"
                      }
                    />
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>

          <div className="shadow-sm rounded border border-light w-100 main-banner">
            <a
              href="http://About.reskills.com/learn-and-earn-program-details/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Cover}
                className="img-fluid"
                style={{ maxHeight: "280px" }}
                alt="Learn and Earn Program Details"
              />
            </a>
          </div>
          <div className="shadow-sm rounded my-3 border border-light  p-3">
            <div className="d-flex justify-content-between">
              <div className="fw-semibold text-primary">
                <img
                  src={ticket}
                  className="mx-1"
                  alt="ticket"
                  style={{ height: "20px", marginBottom: "2px" }}
                />
                Referral Rewards
              </div>
              <div className="fw-semibold text-primary">
                {Math.floor(subscriptionDetail?.referral_count)} Referrals
              </div>
            </div>
            <small className="text-muted mx-1">
              For each successful referral with Learn & Earn subscription, you
              get 10% of the value in points.{" "}
            </small>
            <div className="bg-muted rounded my-3">
              <div className="fw-semibold fw-semibold">
                Be an inspiration to your friends! Invite them to Learn and Earn
                with ReSkills now!
              </div>
              <div className="my-3 d-flex justify-content-between">
                <div className="fw-semibold text-primary">
                  {subscriptionDetail?.referral_code_url}
                </div>
                <div onClick={handleShareModal}>
                  <Button name={"Share"} icon={share} />
                </div>
              </div>
            </div>
          </div>
          <div className="shadow-sm rounded my-3 border border-light  p-3">
            <div className="d-flex justify-content-between">
              <div className="fw-semibold text-primary">
                <img
                  src={book}
                  className="mx-1"
                  alt="ticket"
                  style={{ height: "20px", marginBottom: "2px" }}
                />
                Learning Rewards
              </div>
              <div className="fw-semibold text-primary">
                {Math.floor(subscriptionDetail?.points_from_assessment)} / 100
                points
              </div>
            </div>
            <small className="text-muted mx-1">
              For each onLive certificate you successfully complete, you earn 1
              points. (max of 100 Points){" "}
            </small>
          </div>
          
          {/* Mission Reward */}

          <div className="shadow-sm rounded my-3 border border-light  p-3">
            <div className="d-flex justify-content-between">
              <div className="fw-semibold  text-muted ">
                <img
                  src={star}
                  className="mx-1"
                  alt="ticket"
                  style={{ height: "20px", marginBottom: "2px" }}
                />
                Mission Rewards
              </div>
              <div className="fw-semibold text-primary">10.6 / 100 points</div>
            </div>
            <small className="text-muted mx-1">
              For each mission task you successfully complete, you earn 0.2
              point. (max. of 100 Points){" "}
            </small>

            {mission?.length > 0 &&
                mission?.map((item, index) => {
                    return (

                      <div  key={index} className="shadow-sm rounded my-3 border border-light  p-3">

                     {item?.is_stand_alone ?
                      <div  className="bg-muted rounded my-2 d-flex justify-content-between">
                          <div className="text-muted fw-semibold mx-1 my-2">
                            {item?.mission_name}
                          </div>
                         
                          <div onClick={()=>fetchMissionBegin(item?.id)}>
                              <Button name={"Begin Now"} />
                          </div>
                      </div>
                      :
                      <div className="my-3">
                          <div className="d-flex justify-content-between">
                            <div className="fw-bold ">
                              {item?.mission_name}
                            </div>
                          </div>
                          <small className="text-muted ">
                            The more you share, the more you earn! Complete simple sharing
                            tasks and unlock rewards for helping others join the
                            <br /> learning revolution.
                          </small>
                          </div>}

                     
        
                      <div className=" container d-flex my-3 justify-content-between align-items-center">
                        <div className="progress w-100 ">
                          <div
                            className="progress-bar bg-progress"
                            role="progressbar"
                            style={{ width: "25%" }}
                            aria-valuenow={25}
                            aria-valuemin="0"
                            aria-valuemax={100}
                          ></div>
                        </div>
                        <span className="fw-semibold mx-2 ">2/3</span>
                      </div>
        
                      {
                        <div className="bg-muted rounded  d-flex justify-content-between">
                        <div className="text-muted fw-semibold mx-1 my-2 ">
                          Share with 3 People
                        </div>
                        <div>
                          <button className="primary-color-btn btn btn-primary btn-sm p-2 shadow-sm fw-bold rounded px-3">
                            Begin
                          </button>
                        </div>
                      </div>}
                    
                    </div>

                   
                    );
                })
            }


           
            {/* <div className="shadow-sm rounded my-3 border border-light  p-3">
              <div className="my-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold ">
                    Spread the Word: Share and Earn
                  </div>
                </div>
                <small className="text-muted ">
                  The more you share, the more you earn! Complete simple sharing
                  tasks and unlock rewards for helping others join the
                  <br /> learning revolution.
                </small>
              </div>
              <div className=" container d-flex my-3 justify-content-between align-items-center">
                <div className="progress w-100 ">
                  <div
                    className="progress-bar bg-progress"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow={25}
                    aria-valuemin="0"
                    aria-valuemax={100}
                  ></div>
                </div>
                <span className="fw-semibold mx-2 ">2/3</span>
              </div>

              <div className="bg-muted rounded  d-flex justify-content-between">
                <div className="text-muted fw-semibold mx-1 my-2 ">
                  Share with 3 People
                </div>
                <div
                  className="fw-semibold fw-bold cursor-pointer my-2 d-flex "
                  style={{ color: "#20BB73" }}
                >
                  <img
                    src={Check}
                    className="mx-1 my-1"
                    alt="ticket"
                    style={{ height: "20px", marginBottom: "2px" }}
                  />{" "}
                  <div>Completed</div>
                </div>
              </div>
              <div className="bg-muted rounded  border-top border-bottom d-flex justify-content-between">
                <div className="text-muted fw-semibold mx-1 my-2 ">
                  Social Media Shoutout
                </div>
                <div
                  className="fw-semibold fw-bold cursor-pointer my-2 d-flex "
                  style={{ color: "#20BB73" }}
                >
                  <img
                    src={Check}
                    className="mx-1 my-1"
                    alt="ticket"
                    style={{ height: "20px", marginBottom: "2px" }}
                  />{" "}
                  <div>Completed</div>
                </div>
              </div>
              <div className="bg-muted rounded  d-flex justify-content-between">
                <div className="text-muted fw-semibold mx-1 my-2 ">
                  Invite 5 More Friends
                </div>
                <div
                  className="fw-semibold fw-bold cursor-pointer my-2 d-flex "
                  style={{ color: "#20BB73" }}
                >
                  <img
                    src={Check}
                    className="mx-1 my-1"
                    alt="ticket"
                    style={{ height: "20px", marginBottom: "2px" }}
                  />{" "}
                  <div>Completed</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <ShareModal
        show={shareModal}
        handleClose={handleShareModal}
        shareUrl={subscriptionDetail?.referral_code_url}
      />
      <SubscribeModal 
        show={rewardModal} 
        handleClose={handleRewardModal}
        missionDetail={missionDetail}
      />
    </Account>
  );
};

export default LearnAndEarnTotalPoints;
